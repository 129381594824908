import './App.css'
import NavBar from './navbar/NavBar'
import Home from './home/Home'
import Menu from './menu/Menu'
import About from './about/About'
import Food from './food/Food'
import Footer from './footer/Footer'

import { useState, useEffect } from 'react'

const mobileId = process.env.REACT_APP_BACKGROUND_IMAGE_MOBILE_ID
const desktopId = process.env.REACT_APP_BACKGROUND_IMAGE_ID

function getId(window, desktopId, mobileId) {
    if (window.innerWidth < 800 || window.innerHeight < 600) return mobileId
    
    else return desktopId
}

function getMedia(id, rep, setId) {
    const url = process.env.REACT_APP_CONNECTION_STRING
    fetch(`${url}/${rep}/${id}`, { mode: 'cors' }).then((response) => {
        response.json().then((b) => {
            localStorage.setItem(id, b.url)
            setId(`${url}${b.url}`)
        })
    })
}

function App() {
    const [id, setId] = useState(getId(window, desktopId, mobileId))
    const [imgUrl, setImgUrl] = useState(localStorage.getItem(id))

    useEffect(() => {
        const imgRep = process.env.REACT_APP_IMAGE_REPOSITORY
        getMedia(id, imgRep, setImgUrl)
    }, [id])

    useEffect(() => {
        let listener = () => setId(getId(window, desktopId, mobileId))
        window.addEventListener('resize', listener)
        return () => window.removeEventListener('resize', listener)
    }, [])

    return (
        <div className="bg" style={{ backgroundImage: `url(${imgUrl})` }}>
            <div id="content">
                <NavBar />
                {
                    <section className="ui home" id="home">
                        <Home />
                    </section>
                }
                { 
                    <section className="ui" id="about">
                        <About />
                    </section>
                }
                {/**<section className='ui' id="food"><Food/></section>**/}
                {
                    <section className="ui menu" id="menu">
                        <Menu />
                    </section>
                }
                {
                    <section id="contact">
                        <Footer />
                    </section>
                }
            </div>
        </div>
    )
}

export default App
export { getMedia, getId }
