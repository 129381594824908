import { useEffect, useState } from "react";
import { getMedia, getId } from "../App";
import "./Home.css";

const desktopId = process.env.REACT_APP_BACKGROUND_VIDEO_ID
const mobileId = process.env.REACT_APP_BACKGROUND_VIDEO_MOBILE_ID

function Home(){
    const [id, setId] = useState(getId(window, desktopId, mobileId));
    const [videoUrl, setVideoUrl] = useState(localStorage.getItem(id));


    useEffect(() => {
        const listener = () => {
            let newId = getId(window, desktopId, mobileId);
            setId(newId);};
        window.addEventListener("resize", listener);
        return () =>{window.removeEventListener("resize", listener)};
    }, []);

    useEffect(() => {
        const videoRep = process.env.REACT_APP_VIDEO_REPOSITORY;
        getMedia(id, videoRep, setVideoUrl);
    }, [id]);

    return( 
        <div className="video-container">
            <video id='background-video' src={videoUrl} autoPlay loop muted />
            <div className="overlay">
                <h2><b>Kebabs.</b> <b>Pizzas.</b> <br/> 
                <b><i>Feitos de Raiz</i></b></h2> 
            </div>
        </div>
    )
} export default Home;