import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import './About.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Generate a random avatar
const generateRandomAvatar = (name) => {
  const colors = ['#FFDDC1', '#FFABAB', '#FFC3A0', '#FF677D', '#D4A5A5'];
  const initials = name.split(' ').map(word => word[0]).join('');
  const color = colors[Math.floor(Math.random() * colors.length)];
  return `https://ui-avatars.com/api/?name=${initials}&background=${color.replace('#', '')}&color=fff&size=128`;
};

// Convert star rating to a numeric value
const getStarRating = (rating) => {
  const stars = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'];
  return stars.indexOf(rating) + 1;
};

// ReviewCard component
function ReviewCard({ review }) {
  const starCount = getStarRating(review.starRating);
  const avatar = review.reviewer.displayName
    ? generateRandomAvatar(review.reviewer.displayName)
    : 'https://via.placeholder.com/128?text=No+Avatar'; // Placeholder if no name is provided

  return (
    <div className="review-card p-3 d-flex align-items-start">
      <img src={avatar} alt="Avatar" className="review-avatar me-3" />
      <div className="review-content">
        <h5 className="reviewer-name">{review.reviewer.displayName}</h5>
        <div className="review-stars mb-2">
          {[...Array(starCount)].map((_, i) => (
            <i key={i} className="bi bi-star-fill star-yellow"></i>
          ))}
          {[...Array(5 - starCount)].map((_, i) => (
            <i key={i + starCount} className="bi bi-star"></i>
          ))}
        </div>
        <p className="review-date">{new Date(review.createTime).toLocaleDateString()}</p>
        <p className="review-body">{review.comment}</p>
      </div>
    </div>
  );
}


// About component
function About() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Function to load reviews with a random number between 0 and 20 appended
    async function loadReviews() {
      const randomNumber = Math.floor(Math.random() * 20); // Generate a random number between 0 and 20
      const reviewsPath = `./reviews/reviews${randomNumber}.json`; // Path with random number
      
      // Import JSON file dynamically
      try {
        const reviewsData = await import(`${reviewsPath}`);
        if (Array.isArray(reviewsData.reviews)) {
          setReviews(reviewsData.reviews);
        } else {
          console.error("reviewsData is not an array:", reviewsData);
        }
      } catch (error) {
        console.error("Error loading reviews data:", error);
      }
    }

    loadReviews();
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="text-center">
          <h1 className="about-title">Quem somos?</h1>
          <div className="about-text">
            <p>
              Nossa história começa há várias décadas, na vibrante região do Punjab, na Índia. A família Singh, com uma rica tradição na culinária e hospitalidade, decidiu buscar novas oportunidades em Portugal. Chegaram com pouco, mas com um verdadeiro tesouro: receitas que haviam sido passadas de geração em geração.
            </p>
            <p>
              Com o tempo, o modesto restaurante que abriram em Peniche tornou-se um ponto de encontro não apenas para a comunidade de imigrantes, mas para todos que buscavam uma refeição autêntica, preparada com amor e dedicação. O avô, que trouxe as receitas originais, transmitiu seu conhecimento ao filho, que, por sua vez, ensinou ao neto. Cada geração adicionou seu toque especial, mantendo o espírito e a tradição que deram início a tudo.
            </p>
            <p>
              Hoje, o YoYo Kebab é mais do que um restaurante – é uma celebração da fusão de culturas, do respeito pelas tradições e da paixão por servir a melhor comida possível. Cada prato que servimos conta uma história, e cada cliente que nos visita se torna parte dessa história.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="text-center my-5">
        <Col>
          <h1 className="review-title">O que os nossos clientes acham</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Carousel pause={false} controls={false} indicators={false} interval={3000}>
            {reviews.map((review, index) => (
              review.comment &&
              <Carousel.Item key={index}>
                <ReviewCard review={review} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
