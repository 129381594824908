import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/logo.png';
import './NavBar.css';

function bold(s, className = 'nav-elem') {
    return <b className={className}>{s}</b>;
}

function NavBar() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const title = <img src={logo} alt="logo" id="logo" />;
    const about = bold('Sobre Nós');
    const menu = bold('Menu');
    const contact = bold('Contacto');

    return (
        <Navbar collapseOnSelect fixed="top" expand="lg">
            <Container className="ms-auto end">
                <Navbar.Brand href="#home">{title}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {!isMobile && (
                        <Nav>
                            <Nav.Link href="#about">{about}</Nav.Link>
                            <Nav.Link href="#menu">{menu}</Nav.Link>
                            <Nav.Link href="#contact">{contact}</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
